import { Commit, Module } from 'vuex';
import { State } from '@/store/state';
import { RedpackDocs, RedpackItem } from '@/interface';
import { requestPost } from '@/request/http/http';
import { redpackApi } from '@/request/http';
import { dateFormat, timestamp10, uuid } from '@/utils/util';
import { store } from '@/store';
import { useCustomRouter } from '@/router/custRouter';
import { cloneDeep } from 'lodash';
import { ROUTE_LOGIN } from '@/router/paths';
export interface RedpackState {
  showFloating: boolean;
  redpackList: RedpackItem[] | null;
  redpackDocs: RedpackDocs | null;
  showRedpackPopup: boolean;
  popupData: any;
  hasReceived: boolean;
  hasReceivedMoney: string;
}

export const redpack: Module<RedpackState, State> = {
  namespaced: true,
  state: {
    showFloating: true,
    redpackList: null,
    redpackDocs: null,
    showRedpackPopup: false,
    popupData: null,
    hasReceived: false,
    hasReceivedMoney: '0',
  },
  getters: {
    getDocs(state) {
      return state.redpackDocs;
    },
    getPopupData(state) {
      return state.popupData;
    },
    showRedpackPopup(state) {
      return state.showRedpackPopup;
    },
    getHasReceived(state) {
      return state.hasReceived;
    },
    getHasReceivedMoney(state) {
      return state.hasReceivedMoney;
    },
    listForAll: (state) =>
      state.redpackList
        ? state.redpackList.sort(
            (a, b) =>
              Number(a.collectionStartTime) - Number(b.collectionStartTime)
          )
        : [],
    getInTimePeriodAllRedpack: (_, getters) => (now: number) => {
      return getters.listForAll.find(
        (v: RedpackItem) =>
          now >= Number(v.collectionStartTime) &&
          now <= Number(v.collectionEndTime)
      );
    },
    listForFloating: (state) =>
      state.redpackList
        ? state.redpackList
            .filter((v) => v.showPopup === '1')
            .sort(
              (a, b) =>
                Number(a.collectionStartTime) - Number(b.collectionStartTime)
            )
        : [],
    showRedpackFloating: (state, getters) =>
      state.showFloating && getters.listForFloating.length > 0,
    getInTimePeriodFloatingRedpack: (_, getters) => (now: number) => {
      console.log('[redpack] getInTimePeriodFloatingRedpack', now);
      console.log('[redpack] listForFloating', getters.listForFloating);
      // 获取floating在时间段内的第一个红包
      return getters.listForFloating.find(
        (v: RedpackItem) =>
          now >= Number(v.collectionStartTime) &&
          now <= Number(v.collectionEndTime)
      );
    },
    getWillTimeForFloatingRedpack: (_, getters) => (now: number) => {
      // 获取Floating未来可领红包的倒计时时间
      const nowHasRedpack = getters.getInTimePeriodFloatingRedpack(now);
      const hasReceived = getters.getHasReceived;
      console.log('[redpack] nowHasRedpack', nowHasRedpack, hasReceived);
      if (nowHasRedpack && !hasReceived) {
        return 0;
      } else {
        const redpack = getters.listForFloating.find(
          (v: RedpackItem) => now < Number(v.collectionStartTime)
        );

        if (redpack) {
          return Number(redpack.collectionStartTime) - now;
        } else {
          return 0;
        }
      }
    },
    listForLeftBar: (state) =>
      state.redpackList
        ? state.redpackList
            .filter((v) => v.showSide === '1')
            .sort(
              (a, b) =>
                Number(a.collectionStartTime) - Number(b.collectionStartTime)
            )
        : [],
    showRedpackOnLeftBar: (_, getters) => getters.listForLeftBar.length > 0,
    getInTimePeriodLeftBarRedpack: (_, getters) => (now: number) => {
      // 获取laftBar在时间段内的第一个红包
      return getters.listForLeftBar.find(
        (v: RedpackItem) =>
          now >= Number(v.collectionStartTime) &&
          now <= Number(v.collectionEndTime)
      );
    },
    getWillTimeForLeftBarRedpack: (_, getters) => (now: number) => {
      // 获取laftBar未来可领红包的倒计时时间
      const nowHasRedpack = getters.getInTimePeriodLeftBarRedpack(now);
      const hasReceived = getters.getHasReceived;
      if (nowHasRedpack && !hasReceived) {
        // 目前有可领的红包
        return 0;
      } else {
        // 可能在页面停留很久，然后数据没有更新。所以要判断collectionStartTime是否超过当前时间
        const redpack = getters.listForLeftBar.find(
          (v: RedpackItem) => now < Number(v.collectionStartTime)
        );

        if (redpack) {
          return Number(redpack.collectionStartTime) - now;
        } else {
          return 0;
        }
      }
    },
    autoNotify: (state, getters, rootState, rootGetters) => {
      // 未登录、游客不支持自动提示红包雨
      return (
        rootGetters.loginSuccess &&
        !rootGetters.userType.isGuest &&
        state.redpackDocs?.autoNotify == '1'
      );
    },
  },
  actions: {
    getRedpackList({ commit, getters, dispatch, rootGetters }, payload) {
      requestPost(redpackApi.list, null, (response) => {
        if (Array.isArray(response)) {
          console.log('[redpack] 获取到红包雨列表');
          commit('saveRedpackList', response);
          const redpack = getters.getInTimePeriodAllRedpack(timestamp10());
          if (redpack) {
            // 自动提示启用, check接口
            if (payload?.check && getters.autoNotify) {
              // 更新一下红包雨自动提示缓存，只保留当前红包雨数组中存在的，避免缓存积压
              updateRedpackAuto(response);
              dispatch('getRedpackCheck', { redpack, auto: true });
              dispatch('checkRedpackReceived', { redpack });
            }

            // 优惠中心红包红点，必需登录且非游客
            if (
              payload?.redDot &&
              rootGetters.loginSuccess &&
              !rootGetters.userType.isGuest
            ) {
              // 红包雨自动提示缓存，在这里也可以用。需要自动提示就说明有红点
              updateRedpackAuto(response);
              dispatch('getRedpackCheck', { redpack, redDot: true });
              dispatch('checkRedpackReceived', { redpack });
            }
          }
        }
      });
    },
    // 点击红包的检查
    checkRedpack({ commit, getters, dispatch }, payload) {
      // 1floating 2leftBar
      let nowRedpack: any;
      if (payload?.type === 1) {
        // 首页悬浮
        nowRedpack = getters.getInTimePeriodFloatingRedpack(timestamp10());
      } else if (payload?.type === 2) {
        // 首页侧边栏
        nowRedpack = getters.getInTimePeriodLeftBarRedpack(timestamp10());
      } else if (payload?.type === 3) {
        // 活动中心
        nowRedpack = getters.getInTimePeriodAllRedpack(timestamp10());
      }

      if (nowRedpack) {
        // 存在当前时间段可以领的红包
        if (!store.getters.loginSuccess) {
          // 未登录, 进登录页
          useCustomRouter(null).push(ROUTE_LOGIN.path);
          return;
        }

        dispatch('getRedpackCheck', {
          redpack: nowRedpack,
          type: payload?.type,
          canClaim: payload?.canClaim,
        });
      } else {
        commit('goShowRedpackPopup', null);
      }
    },
    getRedpackDocs({ commit, dispatch }, payload) {
      requestPost(redpackApi.docs, null, (response) => {
        if (response) {
          commit('saveRedpackDocs', response);
          if (!payload?.only) {
            dispatch('getRedpackList', payload);
          }
        }
      });
    },
    getRedpackCheck({ commit }, payload) {
      const params = {
        id: payload.redpack.id,
        uuid: uuid(),
      };
      requestPost(
        redpackApi.check,
        params,
        (response) => {
          if (response.status) {
            if (payload?.auto || payload?.redDot) {
              // 自动提示和优惠中心红点情景，需要判断今天(两个情景共用)是否提示过
              const cacheStr = localStorage.getItem('redpack_auto');
              if (cacheStr && cacheStr.length > 0) {
                try {
                  const cacheObj = JSON.parse(cacheStr);
                  const cache = cacheObj[payload.redpack.id];
                  if (cache) {
                    if (cache != dateFormat(Date.now(), 'yy-MM-dd')) {
                      // 对应的缓存不是今天，表示今天未显示，直接显示
                      payload?.auto &&
                        handleAuto(commit, payload.redpack, cacheObj);
                      payload?.redDot && handleRedDot(true);
                    }
                  } else {
                    // 缓存中没有对应时间段，直接显示
                    payload?.auto &&
                      handleAuto(commit, payload.redpack, cacheObj);
                    payload?.redDot && handleRedDot(true);
                  }
                } catch {
                  // 解析缓存报错，直接显示
                  payload?.auto && handleAuto(commit, payload.redpack, null);
                  payload?.redDot && handleRedDot(true);
                }
              } else {
                // 无缓存，直接显示
                payload?.auto && handleAuto(commit, payload.redpack, null);
                payload?.redDot && handleRedDot(true);
              }
            } else {
              // 手动打开情景，直接显示
              commit('goShowRedpackPopup', cloneDeep(payload.redpack));
              if (payload?.type == 3 && payload?.canClaim) {
                // 这里是点击了优惠中心红包雨
                // 如果存在红点，需要消除红点
                handleRedDot(false);
                // 同步缓存
                const cacheStr = localStorage.getItem('redpack_auto');
                if (cacheStr && cacheStr.length > 0) {
                  try {
                    const cacheObj = JSON.parse(cacheStr);
                    saveRedpackAuto(payload.redpack.id, cacheObj);
                  } catch {
                    // 解析缓存报错
                    saveRedpackAuto(payload.redpack.id, null);
                  }
                } else {
                  // 无缓存
                  saveRedpackAuto(payload.redpack.id, null);
                }
              }
            }
          }
        },
        () => {
          if (payload?.auto || payload?.redDot) {
            // 自动提示情景下，若不符合领取条件，不弹窗
            return;
          }

          commit('goShowRedpackPopup', null);
        }
      );
    },
    checkRedpackReceived({ commit }, payload) {
      const params = {
        id: payload.redpack.id,
        uuid: uuid(),
      };
      requestPost(
        redpackApi.checkReceived,
        params,
        () => {
          commit('updateHasReceived', {
            received: false,
            money: '0',
          });
        },
        (error) => {
          if (error.errorcode !== 1000001) {
            return;
          }
          commit('updateHasReceived', {
            received: true,
            money: error.message,
          });
        }
      );
    },
  },
  mutations: {
    reset(state) {
      state.showRedpackPopup = false;
      state.popupData = null;
      state.hasReceived = false;
      state.hasReceivedMoney = '0';
    },
    saveRedpackList(state, payload) {
      state.redpackList = payload;
    },
    saveRedpackDocs(state, payload) {
      state.redpackDocs = payload;
    },
    hideFloating(state) {
      state.showFloating = false;
    },
    closeRedpackPopup(state) {
      state.showRedpackPopup = false;
    },
    goShowRedpackPopup(state, payload) {
      state.popupData = payload;
      state.showRedpackPopup = true;
    },
    updateHasReceived(state, payload) {
      state.hasReceived = payload.received;
      state.hasReceivedMoney = payload.money;
    },
  },
};

const handleAuto = (commit: Commit, redpack: any, cacheObj: any) => {
  commit('goShowRedpackPopup', cloneDeep(redpack));
  saveRedpackAuto(redpack.id, cacheObj);
};

const handleRedDot = (res: boolean) => {
  store.commit('activities/saveRedDot', {
    key: '0-0-0-6',
    res,
  });
};

const saveRedpackAuto = (id: string, cacheObj: any) => {
  const tempObj: any = cacheObj ? cacheObj : {};
  tempObj[id] = dateFormat(Date.now(), 'yy-MM-dd');
  localStorage.setItem('redpack_auto', JSON.stringify(tempObj));
};

const updateRedpackAuto = (response: any[]) => {
  const cacheStr = localStorage.getItem('redpack_auto');
  if (cacheStr && cacheStr.length > 0) {
    try {
      const cacheObj = JSON.parse(cacheStr);
      const tempObj: any = {};
      response.forEach((v) => {
        const cache = cacheObj[v.id];
        if (cache) {
          tempObj[v.id] = cache;
        }
      });

      localStorage.setItem('redpack_auto', JSON.stringify(tempObj));
    } catch {
      // 解析缓存报错，直接删除缓存
      localStorage.removeItem('redpack_auto');
    }
  }
};
