import { SelectConfig, SingleSelectConfig } from '@/interface';

export const SELECT_VIEW_LIST_DEFAULT_TEXT = 'FILTER_SELECT_ALL_TEXT';

export const DATE_FILTER_CONFIG: SingleSelectConfig = {
  icon: require('assets/images/nb/ic-time.png'),
  title: 'TIME_LABEL',
  selectedIndex: 2,
  defaultIndex: 2,
  list: [
    { text: 'TIME_SELECT_TODAY_TEXT', type: 0 },
    { text: 'TIME_SELECT_YESTERDAY_TEXT', type: 1 },
    { text: 'TIME_SELECT_7_DAYS_TEXT', type: 6 },
    { text: 'TIME_SELECT_15_DAYS_TEXT', type: 14 },
    { text: 'TIME_SELECT_1_MONTH_TEXT', type: 29 },
  ],
};

export const DATE_FILTER_CONFIG_FOR_WITHDRAW_RECORD: SingleSelectConfig = {
  icon: require('assets/images/nb/ic-time.png'),
  title: 'TIME_LABEL',
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: 'TIME_SELECT_7_DAYS_TEXT', type: 7 },
    { text: 'TIME_SELECT_1_MONTH_TEXT', type: 30 },
  ],
};

export const STATUS_FILTER_CONFIG_FOR_WITHDRAW_RECORD: SelectConfig = {
  icon: require('assets/images/nb/ic-type.png'),
  title: 'TYPE_LABEL',
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT, multiple: false },
    { text: 'withdrawal.RECORD_STATUS.1', type: 1, multiple: true },
    { text: 'withdrawal.RECORD_STATUS.2', type: 2, multiple: true },
    { text: 'withdrawal.RECORD_STATUS.6', type: 6, multiple: true },
    { text: 'withdrawal.RECORD_STATUS.3', type: 3, multiple: true },
    { text: 'withdrawal.RECORD_STATUS.4', type: 4, multiple: true },
    { text: 'withdrawal.RECORD_STATUS.5', type: 5, multiple: true },
  ],
};

export const DATE_FILTER_CONFIG_FOR_RECHARGE_RECORD: SingleSelectConfig = {
  icon: require('assets/images/nb/ic-time.png'),
  title: 'TIME_LABEL',
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: 'TIME_SELECT_TODAY_TEXT', type: 1 },
    { text: 'TIME_SELECT_1_MONTH_TEXT', type: 30 },
  ],
};

export const STATUS_FILTER_CONFIG_FOR_RECHARGE_RECORD: SingleSelectConfig = {
  icon: require('assets/images/nb/ic-type.png'),
  title: 'TYPE_LABEL',
  selectedIndex: 0,
  defaultIndex: 0,
  list: [
    { text: SELECT_VIEW_LIST_DEFAULT_TEXT },
    { text: 'shop.RECORD_STATUS.0', type: 0 },
    { text: 'shop.RECORD_STATUS.2', type: 2 },
    { text: 'shop.RECORD_STATUS.1', type: 1 },
    { text: 'shop.RECORD_STATUS.3', type: 3 },
  ],
};

export const NB_ACCOUNT_REG = '1';
export const NB_PHONE_REG = '2';

export const AccountRegex = /^[0-9a-zA-Z_]{6,16}$/; // 账号校验正则
export const EmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; //邮箱
export const PasswordRegex = /^(?=.*[a-zA-Z])(?=.*\d)[0-9a-zA-Z!@()*_-]{6,16}$/;
export const RealnameRegexStr = '^[\\u4e00-\\u9fa5a-zA-Z·]{2,50}$';
export const RealnameFormatter = /[^\u4e00-\u9fa5a-zA-Z·]/g;
export const InviteCodeRegexStr = '^[0-9a-zA-Z]{6}$';
export const InviteCodeFormatter = /[^0-9a-zA-Z]/g;
export const NicknameRegexStr = '^(?!s*$).{1,15}$';

// export const NicknameFormatter = /[^0-9a-zA-Z_]/g;
export const WhatsAppRegexStr = '^.{6,40}$';
export const TelegramRegexStr = '^[0-9a-zA-Z_]{5,40}$';
export const TwitterRegexStr = '^[0-9a-zA-Z_]{4,15}$';

export const PIN_REG_STR = '^\\d{6}$';
export const PIN_FORNATTER = /[^\d●]/g;

export const CPFRemarkRegexStr = '^.{1,10}$';
export const PixRegStr = '^\\d{3}.\\d{3}.\\d{3}-\\d{2}$';
export const removeExceptDigit = /[^\d]/g;

export const DeviceRegexStr = '^.{1,12}$';

// export const DATE_FORMAT = {
//   //日期格式
//   yymmdd_hhmmss: "dd/MM/yy hh:mm:ss",
//   yymmdd_hhmm: "dd/MM/yy hh:mm",
//   yymmdd: "dd/MM/yy",
//   mmdd_hhmm: "dd/MM hh:mm",
// };

export const CATEGORY_BTN_TYPE = {
  total: 0,
  slot: 1,
  lottery: 2,
  live: 3,
  sport: 4,
  cards: 5,
  fishing: 6,
  popular: 7,
  favorites: 8,
  bonus: 9,
};

export const CATEGORY_BTN_LIST = [
  {
    categoryId: CATEGORY_BTN_TYPE.slot,
    name: 'categorybtn.SLOT',
    icon: require('@/assets/images/nb/category/slot-color.svg'),
    iconActive: require('@/assets/images/nb/category/slot-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.lottery,
    name: 'categorybtn.LOTTERY',
    icon: require('@/assets/images/nb/category/lottery-color.svg'),
    iconActive: require('@/assets/images/nb/category/lottery-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.live,
    name: 'categorybtn.LIVE',
    icon: require('@/assets/images/nb/category/live-color.svg'),
    iconActive: require('@/assets/images/nb/category/live-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.sport,
    name: 'categorybtn.SPORT',
    icon: require('@/assets/images/nb/category/sport-color.svg'),
    iconActive: require('@/assets/images/nb/category/sport-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.cards,
    name: 'categorybtn.CARDS',
    icon: require('@/assets/images/nb/category/cards-color.svg'),
    iconActive: require('@/assets/images/nb/category/cards-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.fishing,
    name: 'categorybtn.FISHING',
    icon: require('@/assets/images/nb/category/fishing-color.svg'),
    iconActive: require('@/assets/images/nb/category/fishing-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.popular,
    name: 'categorybtn.POPULAR',
    icon: require('@/assets/images/nb/category/popular-color.svg'),
    iconActive: require('@/assets/images/nb/category/popular-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.favorites,
    name: 'categorybtn.FAVORITES',
    icon: require('@/assets/images/nb/category/favorite-color.svg'),
    iconActive: require('@/assets/images/nb/category/favorite-color.svg'),
  },
  {
    categoryId: CATEGORY_BTN_TYPE.bonus,
    name: 'categorybtn.BONUS',
    icon: require('@/assets/images/nb/category/bonus-color.svg'),
    iconActive: require('@/assets/images/nb/category/bonus-color.svg'),
  },
];

export const OcTypeName = {
  '1': 'Google',
  '2': 'Facebook',
};

export const AGENT_CODE = 'agentCode';
export const CHANNEL_CODE = 'channelCode';
export const APPSFLEER_ID = 'appsflyerid';
export const APP_IDS = 'appIds';
export const FB_CLID = 'fbclid';

export const DEVICE_TYPE = {
  WEB_H5: 'h5',
  WEB_PWA: 'pwa',
  APP_ANDROID: 'android',
  APP_IOS: 'ios',
};

export const DefaultCurrencySymbol = 'R$';
